import React from 'react';
import ICN_ARROW_DOWN from 'src/assets/icons/icn-arrow-down.svg';
import MultipartUpload from 'src/utils/MultipartUpload';

interface IProps {
  data: MultipartUpload[];
}

function AppFileUploadList(props: IProps) {
  const { data } = props;
  const [, forceUpdate] = React.useReducer((x) => !x, false);
  React.useLayoutEffect(() => {
    const remove = data.map((it) => it.addEventListener('done', () => forceUpdate()));
    const progress = data.map((it) => it.addEventListener('progress', () => forceUpdate()));

    return () => {
      remove.forEach((it) => it());
      progress.forEach((it) => it());
    };
  }, [data]);

  const [open, setOpen] = React.useState(true);
  const handleOpen = React.useCallback(() => setOpen((x) => !x), []);

  return data.length > 0 ? (
    <div
      style={{
        position: 'fixed',
        right: 5,
        bottom: 5,
        width: '500px',
        maxHeight: '700px',
        overflow: 'auto',
        backgroundColor: '#E5F8F3',
        zIndex: 3,
        padding: '30px',
        borderRadius: '20px',
        border: '1px solid black',
      }}
    >
      <div onClick={handleOpen}>
        <img
          src={ICN_ARROW_DOWN}
          alt={'arrow'}
          style={open ? { float: 'right' } : { float: 'right', transform: 'rotate(180deg)' }}
        />
        {data.filter((t) => t.status === 'DONE').length !== data.length ? (
          <>
            <div>
              파일 업로드 중 ({data.filter((t) => t.status === 'DONE').length}/{data.length})
            </div>
            <div>해당 창을 닫거나 새로고침 할 경우 파일 업로드가 중단됩니다.</div>
          </>
        ) : (
          <div>파일 업로드가 완료되었습니다.</div>
        )}
      </div>
      {open && (
        <div className="p-3">
          {data.map((uploader: MultipartUpload, idx) => {
            return (
              <div key={idx}>
                <div>{uploader.namedFile.file.name}</div>
                <div
                  style={{
                    background: `linear-gradient(to right,#006BE5 ${
                      (uploader.progress.value / uploader.progress.max) * 100
                    }%, #F2F7FC 0%)`,
                    width: '400px',
                    borderRadius: '10px',
                    height: '20px',
                    border: '0.5px solid black',
                  }}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  ) : null;
}

export default AppFileUploadList;
