import { APILanguages } from 'src/types/api';

export const LANGUAGES_SUPPORT = [
  'KOREAN',
  'ENGLISH',
  'JAPANESE',
  'SIMPLIFIED_CHINESE',
  'TAIWAN_CHINESE',
] as ReadonlyArray<APILanguages>;

export const LANGUAGES = [
  ...LANGUAGES_SUPPORT,
  'SPANISH',
  'VIETNAMESE',
  'INDONESIAN',
  'THAI',
  'RUSSIAN',
  'FRENCH',
  'PORTUGUESE',
  'GERMAN',
  'ETC',
] as ReadonlyArray<APILanguages>;
