import { useQuery } from '@apollo/client';
import { CButton } from '@coreui/react';
import { useMount } from '@voithru/front-core';
import { nanoid } from 'nanoid';
import React from 'react';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import ICN_DELETE from 'src/assets/icons/icn-delete-24px.svg';
import ICN_DOWNLOAD from 'src/assets/icons/icn-download-24px.svg';
import ICN_PREVIEW from 'src/assets/icons/icn-preview-24px.svg';
import ICN_UPLOAD from 'src/assets/icons/icn-upload-24px.svg';
import { ButtonBase } from 'src/components/atoms/styled/button';
import { Column, Row } from 'src/components/atoms/styled/layout';
import { useAuth, useMingloAuth } from 'src/components/context/AuthContext';
import { useContentsUploaderService } from 'src/components/context/ContentsUploaderContext';
import { useUploaderContext } from 'src/components/context/UploaderContext';
import FormModal from 'src/components/organisms/FormModal';
import { JobResultFileType } from 'src/types/api/JobResult';
import { File as FileGqlType, JobFile, JobResultFile, Maybe } from 'src/types/graphql';
import { isAxiosError } from 'src/utils/api/axios';
import { dateFormat } from 'src/utils/date';
import { handleEnterKeyDown } from 'src/utils/dom';
import { downloadAPIFile, NamedFile, selectFile, separateFileIntoNameAndExtension } from 'src/utils/files';
import MultipartUpload from 'src/utils/MultipartUpload';
import { getPreviewUrl, previewToWindow } from 'src/utils/preview';
import QueueTask from 'src/utils/QueueTask';
import { getByteString } from 'src/utils/string';
import {
  AdditionalServices,
  ResultType,
  Source_Language,
  Translate_Language,
  WorkRange,
  WorkTarget,
} from 'src/utils/translate';
import { GQL_JOB_RESULT_FILES } from './gql';
import { JobResultFilesQuery, JobResultFilesQueryVariables } from './gql.generated';
import { JobResultFileDownloadModalWrap } from './styled';

interface Props {
  projectId: number;
  jobIds: string[];

  onDismiss: () => void;

  transactionId: string;
}

function JobResultFileDownloadModal(props: Props) {
  const { projectId, jobIds, onDismiss, transactionId } = props;

  const queueTask = React.useMemo(() => new QueueTask(), []);

  const { user } = useAuth();
  const minglo = useMingloAuth();

  const registerService = useContentsUploaderService();
  const context = useUploaderContext();

  const manager = React.useMemo(
    () => registerService.createUploadService(transactionId),
    [registerService, transactionId]
  );

  const { loading, error, data, refetch } = useQuery<JobResultFilesQuery, JobResultFilesQueryVariables>(
    GQL_JOB_RESULT_FILES,
    {
      variables: {
        jobIds,
        hermesAccountId: minglo.user!.id,
        projectId,
      },
      onError: errorLogger.error,
    }
  );

  const { jobs, workerJobResultGrants } = data ?? {};

  const [editFileId, setEditFileId] = React.useState<string>();

  const editFileRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (editFileId === undefined) return;

    editFileRef.current?.focus();
  }, [editFileId]);

  const handleRefetch = React.useCallback(() => {
    refetch({
      jobIds,
    });
  }, [jobIds, refetch]);

  const handleFileName = React.useCallback(
    async (targetFile: FileGqlType, fileExtension: string) => {
      if (
        !editFileRef.current?.value ||
        !targetFile ||
        !(targetFile.name !== editFileRef.current?.value && editFileId)
      ) {
        return;
      }

      await api.file
        .item(editFileId)
        .put({
          id: targetFile.id,
          isDirectory: targetFile.isDirectory!,
          parentDirectoryId: targetFile.parentDirectoryId ?? undefined,
          s3Path: targetFile.s3Path!,
          volume: targetFile.volume!,
          name: editFileRef.current?.value + fileExtension,
        })
        .catch(errorLogger.error);

      handleRefetch();

      setEditFileId(undefined);
    },
    [editFileId, handleRefetch]
  );

  const fileDownload = React.useCallback(
    (file: FileGqlType) => {
      if (!file.name) {
        return;
      }

      queueTask.dispatch(downloadAPIFile, {
        id: file.id,
        name: file.name,
      });
    },
    [queueTask]
  );

  const handlePreview = React.useCallback(async (file: FileGqlType) => {
    const data = await getPreviewUrl({
      id: file.id,
      name: file.name ?? '',
    }).catch(errorLogger.error);

    if (!data) return;

    previewToWindow(data);
  }, []);

  const handleJobFilesDownload = React.useCallback(
    (jobFiles: Maybe<Array<Pick<JobFile, 'fileId' | 'file'>>>) => {
      if (!jobFiles) {
        return;
      }

      for (const item of jobFiles) {
        item.file && fileDownload(item.file);
      }
    },
    [fileDownload]
  );

  const handleJobResultFileDownload = React.useCallback(
    (jobResultFiles: Maybe<Array<Omit<JobResultFile, 'id' | 'jobResult' | 'jobResultId'>>>) => {
      if (!jobResultFiles) {
        return;
      }

      for (const item of jobResultFiles) {
        item.file && fileDownload(item.file);
      }
    },
    [fileDownload]
  );

  const handleFileAllDownload = React.useCallback(() => {
    if (!jobs) return;

    for (const job of jobs) {
      if (job.jobFiles) {
        for (const item of job.jobFiles) {
          item.file && fileDownload(item.file);
        }
      }

      if (job.jobResults) {
        for (const item of job.jobResults) {
          if (item.jobResultFiles) {
            for (const resultFile of item.jobResultFiles) {
              resultFile.file && fileDownload(resultFile.file);
            }
          }
        }
      }
    }
  }, [fileDownload, jobs]);

  const handleJobResultFileDelete = React.useCallback(
    async (jobId: string, jobResultId: string, fileId: string) => {
      const numJobResultId = parseInt(jobResultId);

      const data = {
        jobResultId: numJobResultId,
        fileId,
      };

      if (window.confirm('해당 파일을 삭제하시겠습니까?')) {
        const res = await api.jobs.item(parseInt(jobId)).jobResultItem(numJobResultId).files.item(fileId).delete(data);
        if (isAxiosError(res)) {
          throw res;
        }

        refetch({
          jobIds,
        });
      }
    },
    [jobIds, refetch]
  );

  const handleUpload = React.useCallback(
    async (jobId: string, jobResultId: string, multiple: boolean = false, directory: boolean = false) => {
      if (user?.role !== 'ADMIN' && workerJobResultGrants?.every((it) => it.jobResultId !== Number(jobResultId))) {
        return window.alert('업로드 권한이 없습니다.');
      }

      const files = await selectFile({ multiple, directory });

      const numJobId = parseInt(jobId);
      const numJobResultId = parseInt(jobResultId);

      const uploadFile = async (file: File, fileIndex: number) => {
        const namedFile: NamedFile = {
          id: nanoid(),
          name: file.webkitRelativePath || file.name,
          file: file,
        };

        const fileType = namedFile.name.split('.');
        let fileTypeUpperCase = fileType[fileType.length - 1].toUpperCase();
        const JobResultFileTypes = ['SRT', 'PSD', 'IMAGE', 'TEXT', 'VIDEO', 'OTHER'];

        if (!JobResultFileTypes.includes(fileTypeUpperCase)) {
          fileTypeUpperCase = 'OTHER';
        }

        const uploader = new MultipartUpload(namedFile);

        // job 사용하지않음
        await uploader.ready('JOB');

        uploader.addEventListener('done', async () => {
          if (!uploader.fileId) {
            return;
          }

          const data = [
            {
              jobResultId: numJobResultId,
              fileId: uploader.fileId,
              fileType: fileTypeUpperCase as JobResultFileType,
              index: fileIndex,
            },
          ];
          await api.jobs
            .item(numJobId)
            .jobResultItem(numJobResultId)
            .files.post(data)
            .catch(errorLogger.error)
            .finally(() => {
              refetch({
                jobIds,
              });
            });
        });

        manager.register(uploader);

        uploader.start();
      };

      if (files) {
        const rt: Array<Promise<Promise<void>>> = [];

        const lastIndex = Math.max(
          ...(jobs
            ?.find((it) => it.id === jobId)
            ?.jobResults?.find((it) => it.id === jobResultId)
            ?.jobResultFiles?.map((a) => a.index ?? 0) ?? [])
        );

        Array.from(files).forEach((file, index) => {
          rt.push(queueTask.dispatch(uploadFile, file, lastIndex + index + 1));
        });

        await Promise.all(rt);
      }
    },
    [jobIds, jobs, manager, queueTask, refetch, user?.role, workerJobResultGrants]
  );

  const handleSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      onDismiss();
    },
    [onDismiss]
  );

  useMount(() => {
    context.register(transactionId, manager);
  });

  return (
    <FormModal title={'작업 결과물 첨부파일'} visible={true} size={'xl'} onDismiss={onDismiss} onSubmit={handleSubmit}>
      {loading && <div>Loading...</div>}
      {error && (
        <div>
          <h2>{error.name}</h2>
          <h3>{error.message}</h3>
        </div>
      )}
      {!loading && !error && (
        <JobResultFileDownloadModalWrap>
          <Row className={'buttonWrap'}>
            <ButtonBase onClick={handleFileAllDownload}>
              전체 다운로드
              <img src={ICN_DOWNLOAD} alt={'download'} />
            </ButtonBase>
          </Row>
          <Column>
            {jobs?.map((it) => (
              <React.Fragment key={it.id}>
                <Row className={'titleWrap'}>
                  <span>CON-{it.id}</span>
                  <span>{it.name}</span>
                  <ButtonBase onClick={() => handleJobFilesDownload(it.jobFiles)}>
                    <img src={ICN_DOWNLOAD} alt={'download'} />
                  </ButtonBase>
                </Row>
                {it.jobResults?.map((jobResult) => {
                  jobResult.jobResultFiles?.sort((a, b) => (a.index ?? 0) - (b.index ?? 0));
                  return (
                    <Column key={jobResult.id}>
                      <Row>
                        <span>JR-{jobResult.id} </span>
                        <span>
                          {jobResult.productOrder?.productType && WorkRange[jobResult.productOrder?.productType]}/
                          {jobResult.productOrder?.sourceLanguage &&
                            Source_Language[jobResult.productOrder?.sourceLanguage]}
                          /
                          {jobResult.productOrder?.translateLanguage
                            ? Translate_Language[jobResult.productOrder?.translateLanguage]
                            : '-'}
                          /{jobResult.productOrder?.deliveryType && ResultType[jobResult.productOrder?.deliveryType]}/
                          {jobResult.productOrder?.productSource
                            ? WorkTarget[jobResult.productOrder?.productSource]
                            : '-'}
                          /
                          {jobResult.productOrder?.productOptionOrders
                            ?.map((option) => AdditionalServices[option.type!])
                            .join(',')}
                        </span>
                        <ButtonBase onClick={() => handleJobResultFileDownload(jobResult?.jobResultFiles)}>
                          <img src={ICN_DOWNLOAD} alt={'download'} />
                        </ButtonBase>
                      </Row>
                      <Column>
                        {jobResult.jobResultFiles?.map((jobResultFile) => {
                          if (jobResultFile.file) {
                            const { file, fileId } = jobResultFile;

                            const splitJobResultFileName = separateFileIntoNameAndExtension(file.name);

                            return (
                              <Row key={file.id}>
                                {editFileId === fileId && (
                                  <span>
                                    <input
                                      defaultValue={splitJobResultFileName[0] ?? ''}
                                      ref={editFileRef}
                                      onBlur={() => handleFileName(file, splitJobResultFileName[1] || '')}
                                      onKeyDown={(e) => handleEnterKeyDown(e, editFileRef)}
                                    />
                                    {splitJobResultFileName[1]}
                                  </span>
                                )}
                                {editFileId !== fileId && (
                                  <span
                                    onDoubleClick={() => user?.role === 'ADMIN' && setEditFileId(jobResultFile.fileId)}
                                  >
                                    {file.name}
                                  </span>
                                )}
                                <span>
                                  {file.fileUploadStartDateTime &&
                                    dateFormat(file.fileUploadStartDateTime, 'YYYY.MM.DD')}
                                </span>
                                <span>{file.volume && getByteString(file.volume)}</span>
                                <span>{file.account?.name}</span>
                                <ButtonBase onClick={() => handlePreview(file)}>
                                  <img src={ICN_PREVIEW} alt={'preview'} />
                                </ButtonBase>
                                <ButtonBase onClick={() => fileDownload(file)}>
                                  <img src={ICN_DOWNLOAD} alt={'download'} />
                                </ButtonBase>
                                <ButtonBase
                                  onClick={() =>
                                    jobResultFile.file?.id &&
                                    handleJobResultFileDelete(it.id, jobResult.id, jobResultFile.file?.id).catch(
                                      errorLogger.error
                                    )
                                  }
                                >
                                  <img src={ICN_DELETE} alt={'delete'} />
                                </ButtonBase>
                              </Row>
                            );
                          }

                          return null;
                        })}
                      </Column>
                      {jobResult.status !== 'REVIEWABLE' && (
                        <CButton
                          color={'light'}
                          style={{ marginTop: 8 }}
                          onClick={() => handleUpload(it.id, jobResult.id, true, false)}
                        >
                          <img src={ICN_UPLOAD} alt={'upload'} />
                          파일 첨부하기
                        </CButton>
                      )}
                      {jobResult.status !== 'REVIEWABLE' && (
                        <CButton
                          color={'light'}
                          style={{ marginTop: 8 }}
                          onClick={() => handleUpload(it.id, jobResult.id, false, true)}
                        >
                          <img src={ICN_UPLOAD} alt={'upload'} />
                          폴더 첨부하기
                        </CButton>
                      )}
                    </Column>
                  );
                })}
              </React.Fragment>
            ))}
          </Column>
        </JobResultFileDownloadModalWrap>
      )}
    </FormModal>
  );
}

export default JobResultFileDownloadModal;
