import {
  CButton,
  CCol,
  CContainer,
  CFormCheck,
  CHeaderBrand,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import { useMount } from '@voithru/front-core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import { useLoading } from 'src/components/context/ApplicationContext';
import { useContentsUploaderService, useGoogleFiles } from 'src/components/context/ContentsUploaderContext';
import { useUploaderContext } from 'src/components/context/UploaderContext';
import FileDropBox from 'src/components/molecules/FileDropBox';
import ContentFileList from 'src/components/organisms/ContentFileList';
import { GoogleFile } from 'src/types/api/File';
import { ProductOrderId, ProductOrderResponse } from 'src/types/api/ProductOrder';
import { ProjectResponse } from 'src/types/api/Project';
import { isAxiosError } from 'src/utils/api/axios';
import { NamedFile } from 'src/utils/files';
import { FileGroup, FileGroupWithGoogle } from 'src/utils/JobRegisterService';
import { getTransactionOrdersStorage } from 'src/utils/storages';
import { getByteString } from 'src/utils/string';
import {
  AdditionalServices,
  ContentType,
  ResultType,
  Source_Language,
  Translate_Language,
  WorkRange,
} from 'src/utils/translate';
import { ITransactionId, PageState } from '../../type';

interface FormData {
  files: { id: string; name: string }[];
  orders?: { checked: boolean }[];
}

interface IFileUploadPageProps {
  projectId: number;
  project?: ProjectResponse;
}
const INIT: FormData = { files: [] };
function ProjectCreateUpload(props: IFileUploadPageProps & ITransactionId) {
  const { transactionId, projectId } = props;

  const history = useHistory<PageState>();
  const { pathname, search, state: pageState } = useLocation<PageState>();
  const loading = useLoading();

  const registerService = useContentsUploaderService();
  const googleFiles = useGoogleFiles();
  const context = useUploaderContext();
  const manager = React.useMemo(
    () => registerService.createUploadService(transactionId),
    [registerService, transactionId]
  );

  const { register, handleSubmit, getValues, setValue } = useForm<FormData>({ defaultValues: INIT });

  const [orders, setOrders] = React.useState<ProductOrderResponse[]>([]);
  const getOrders = React.useCallback(async () => {
    await api.project
      .item(projectId)
      .productOrders()
      .then((res) => {
        setOrders([...res.data]);
      });
  }, [projectId]);

  const [project, setProject] = React.useState<ProjectResponse>();
  const getByProjectId = React.useCallback(async () => {
    await api.project
      .item(projectId)
      .get()
      .then(async (res) => {
        const { data } = res;
        registerService.registerOptions(transactionId, { project: data });
        setProject(data);
      });
  }, [projectId, registerService, transactionId]);

  useMount(() => {
    getOrders();
    getByProjectId();
    context.register(transactionId, manager);
  });

  const [orderedFileGroups, setOrderedFileGroups] = React.useState<FileGroup[]>([]);

  const [googleFileGroups, setGoogleFileGroups] = React.useState<GoogleFile[]>([]);

  const onSelectFiles = React.useCallback(
    (fgs: FileGroup[]) => {
      const files = fgs.flatMap((fg) => fg.files);
      for (const namedFile of files) {
        manager.register(namedFile);
      }
      fgs.forEach((value, index) => (value.index = orderedFileGroups.length + googleFileGroups.length + index));
      setOrderedFileGroups((prev) => prev.concat(fgs));
    },
    [googleFileGroups.length, manager, orderedFileGroups.length]
  );

  const handleGoogleFileGroups = React.useCallback(
    (file: GoogleFile, index: number) => {
      setGoogleFileGroups((prev) =>
        prev.concat({
          ...file,
          index: prev.length + orderedFileGroups.length + index,
        })
      );
    },
    [orderedFileGroups.length]
  );

  const handleRename = React.useCallback(
    (targetId: string, name: string) => {
      const formFiles = getValues('files');
      const targetIndex = formFiles.findIndex((it) => it.id === targetId);
      setValue(`files.${targetIndex < 0 ? formFiles?.length || 0 : targetIndex}`, { id: targetId, name });
    },
    [getValues, setValue]
  );

  const handleSort = React.useCallback((fileGroups: FileGroup[], googleFiles: GoogleFile[]) => {
    setOrderedFileGroups(fileGroups);
    setGoogleFileGroups(googleFiles);
  }, []);

  const handleRemove = React.useCallback(
    (namedFile: FileGroup) => {
      namedFile.files.forEach((file) => manager.unregister(file.id));
      setOrderedFileGroups((prev) => {
        const targetIndex = prev.findIndex((it) => it.id === namedFile.id);
        if (targetIndex < 0) {
          return prev;
        }

        const arr = Array.from(prev);
        arr.splice(targetIndex, 1);
        return arr;
      });
    },
    [manager]
  );

  const handleGoogleFileRemove = React.useCallback((googleFile: GoogleFile) => {
    setGoogleFileGroups((prev) => {
      return prev.filter((it) => {
        return it.id !== googleFile.id;
      });
    });
  }, []);

  const submit = React.useMemo(
    () =>
      loading(async (form: FormData) => {
        if ((!manager || manager.uploaders.length === 0) && googleFileGroups.length === 0) {
          return;
        }

        if (!form.orders || form.orders.length <= 0 || form.orders.filter((it) => it.checked).length === 0) {
          return;
        }

        try {
          const res = await api.project.item(projectId).status('REQUESTED');

          if (isAxiosError(res)) {
            throw res;
          }

          const storage = getTransactionOrdersStorage(transactionId);
          storage.item = form.orders.map((_, index) => orders[index]?.id).filter(Boolean) as ProductOrderId[];

          const fileGroups = orderedFileGroups.map((fileGroup, index) => ({
            ...fileGroup,
            name: form.files?.find((it) => it.id === fileGroup.id)?.name || fileGroup.name,
            index: fileGroup.index,
          }));
          if (fileGroups.length > 0) {
            registerService.start(transactionId, fileGroups, 'CREATE_JOB');
          }
          if (googleFileGroups.length > 0) {
            for (const it of googleFileGroups) {
              const name = form.files?.find((item) => item.id === it.id)?.name || it.name;
              it.name = name;
              await api.jobs.post({
                job: {
                  name: name,
                  projectId,
                  index: it.index,
                  status: 'REQUESTED',
                  category: project?.category!,
                  accountId: project?.accountId,
                  managerId: project?.managerId,
                },
                productOrdersIds: storage.item,
                jobFiles: it.jobFiles.map((jobFile) => {
                  return {
                    fileId: jobFile.id,
                    fileType: 'CONTENTS_FILE',
                    index: jobFile.index,
                  };
                }),
              });
            }

            googleFiles.set(transactionId, googleFileGroups);
          }

          history.replace({ pathname, search, state: { ...pageState, step: 'DONE' } });
        } catch (error) {
          errorLogger.error(error);
        }
      }),
    [
      loading,
      manager,
      googleFileGroups,
      projectId,
      transactionId,
      orderedFileGroups,
      history,
      pathname,
      search,
      pageState,
      orders,
      registerService,
      googleFiles,
      project?.category,
      project?.accountId,
      project?.managerId,
    ]
  );

  const compareFiles = React.useMemo(() => {
    const compare = [...orderedFileGroups, ...googleFileGroups];
    return compare
      .sort((a, b) => {
        return a.index - b.index;
      })
      .map((it): FileGroupWithGoogle => {
        const { id, name, index } = it;
        const size = it['files']
          ? it['files'].map((f: NamedFile) => f.file.size).reduce((prev: number, cur: number) => prev + cur, 0)
          : it['volume'];
        return {
          id,
          name,
          index,
          size: getByteString(size),
          item: it,
        };
      });
  }, [orderedFileGroups, googleFileGroups]);

  const prev = React.useCallback(() => {
    if (!window.confirm('이전 단계로 이동할 경우, 파일 업로드를 다시 진행해야 합니다. 그래도 진행하시겠습니까?')) {
      return;
    }

    history.replace({ pathname, search, state: { ...pageState, step: 'ORDER' } });
  }, [history, pageState, pathname, search]);

  return (
    <div className="bg-light d-flex flex-column justify-content-center">
      <CHeaderBrand>프로젝트 등록하기 3/4 - 콘텐츠 업로드</CHeaderBrand>
      <CContainer>
        <form onSubmit={handleSubmit(submit)}>
          <div style={{ paddingBottom: '20px' }}>
            <div>콘텐츠 파일 업로드</div>
            <div>
              <FileDropBox
                onSelectFiles={onSelectFiles}
                projectAccountId={project?.accountId}
                onGoogleFileGroups={handleGoogleFileGroups}
              />
            </div>
            <div>
              {compareFiles.length > 0 && (
                <ContentFileList
                  files={compareFiles}
                  onSort={handleSort}
                  onNameChange={handleRename}
                  onDelete={handleRemove}
                  onGoogleFileDelete={handleGoogleFileRemove}
                />
              )}
            </div>
            <div style={{ marginTop: '30px' }}>
              <div className="d-flex">
                현재 의뢰하시는 프로젝트의 컨텐츠 타입은{' '}
                <div style={{ color: 'blue' }}>{project?.category ? ContentType[project.category] : ''}</div> 입니다.
              </div>
              <div>업로드한 콘텐츠 파일에 대하여 의뢰하고자 하는 작업을 선택해주세요.</div>
              <div style={{ padding: '10px', fontSize: '13px', color: 'darkgray' }}>
                <div>콘텐츠별 작업단위로 정산 및 납품이 이루어집니다.</div>
                <div>프로젝트 의뢰완료 후에도 콘텐츠를 추가 생성할 수 있습니다.</div>
              </div>
              <div>
                <CTable bordered style={{ width: 'max-content', minWidth: '100%' }}>
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell>-</CTableHeaderCell>
                      <CTableHeaderCell>작업유형</CTableHeaderCell>
                      <CTableHeaderCell>작업언어</CTableHeaderCell>
                      <CTableHeaderCell>추가작업</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {orders.map((order, idx) => {
                      const { productType, deliveryType, sourceLanguage, translateLanguage, options } = order;

                      return (
                        <CTableRow key={idx}>
                          <CTableDataCell>
                            <CRow>
                              <CCol sm={8}>
                                <CRow>
                                  <CCol style={{ minWidth: 'max-content' }}>
                                    <CFormCheck {...register(`orders.${idx}.checked`)} />
                                  </CCol>
                                </CRow>
                              </CCol>
                            </CRow>
                          </CTableDataCell>
                          <CTableDataCell>{`${WorkRange[productType]}${
                            (deliveryType && '_' + ResultType[deliveryType]) ?? ''
                          }`}</CTableDataCell>
                          <CTableDataCell>{`${Source_Language[sourceLanguage]} ${
                            (translateLanguage && ' -> ' + Translate_Language[translateLanguage]) ?? ''
                          }`}</CTableDataCell>
                          <CTableDataCell>
                            {options?.map((option) => AdditionalServices[option.type]).join('/') || '없음'}
                          </CTableDataCell>
                        </CTableRow>
                      );
                    })}
                  </CTableBody>
                </CTable>
              </div>
            </div>
            <div>
              <CButton color="secondary" onClick={prev}>
                뒤로가기
              </CButton>
              <CButton style={{ width: '80px' }} type={'submit'}>
                다음
              </CButton>
            </div>
          </div>
        </form>
      </CContainer>
    </div>
  );
}

export default ProjectCreateUpload;
