import { cn, useMount } from '@voithru/front-core';
import React from 'react';
import { generatePath, useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import ICN_PLUS from 'src/assets/icons/icn-circle-plus.svg';
import ICN_DOWNLOAD from 'src/assets/icons/icn-download-24px.svg';
import StatusLabel from 'src/components/atoms/StatusLabel';
import { ButtonBase } from 'src/components/atoms/styled/button';
import { Title } from 'src/components/atoms/styled/element';
import { CheckBox } from 'src/components/atoms/styled/form';
import { Column, Row } from 'src/components/atoms/styled/layout';
import { JobsResponse, JobsStatus, JobsStatusUpdate } from 'src/types/api/Job';
import { ProjectResponse } from 'src/types/api/Project';
import { AccountResponseWithCompany } from 'src/types/api/User';
import { handleEnterKeyDown } from 'src/utils/dom';
import { isAxiosError } from 'src/utils/api/axios';
import { dateFormat, recentDate } from 'src/utils/date';
import { downloadAPIFile } from 'src/utils/files';
import AccountSelectModal from 'src/components/organisms/AccountSelectModal';
import ApprovedJobResultModal from 'src/components/pages/ProjectDetailPage/components/ApprovedJobResultModal';
import FileDownloadModal from 'src/components/organisms/FileDownloadModal';
import JobStatusUpdateModal from 'src/components/organisms/JobStatusUpdateModal';
import { getJobsByProjectId } from 'src/utils/api/getter';
import { useProjectDetailInfoContext } from '../../context';
import { JobListWrapper } from './styled';
import AppPaths from 'src/constants/AppPaths';

interface Props {
  project: ProjectResponse;
  projectId: number;

  onChecked: (id: number, bool?: boolean) => void;
  onProjectChange(): void;
  forceUpdate: () => void;

  transactionId: string;
}

type Status = JobsStatus | 'ALL';

type JobStatusType = Partial<Record<Status, number>>;

function JobList(props: Props) {
  const { projectId, onChecked, forceUpdate, transactionId } = props;
  const history = useHistory();
  const { jobs, setJobs, jobResults, jobsChecks, productOrderChecks, jobManagerMap, jobFileMap, productOrderMap } =
    useProjectDetailInfoContext();
  const [allChecked, setAllChecked] = React.useState(() => false);

  const jobStatus = React.useMemo<JobStatusType>(() => {
    if (!jobs) {
      return {};
    }
    let status = jobs.reduce((obj: JobStatusType, it) => {
      obj[it.status] = (obj[it.status] ?? 0) + 1;
      return obj;
    }, {});

    return {
      ...status,
      ALL: jobs.length,
    };
  }, [jobs]);

  const [filterStatus, setFilterStatus] = React.useState<Status>('ALL');

  const [editNameId, setEditNameId] = React.useState<number>();
  const [editDeadLineId, setEditDeadLineId] = React.useState<number>();
  const [editWorkSizeId, setEditWorkSizeId] = React.useState<number>();

  const [filesModalVisible, setFilesModalVisible] = React.useState(false);
  const [adminSelectVisible, setAdminSelectVisible] = React.useState(false);
  const [adminSelectId, setAdminSelectId] = React.useState<number>();

  const editNameRef = React.useRef<HTMLInputElement>(null);
  const editDeadLineRef = React.useRef<HTMLInputElement>(null);
  const editWorkSizeRef = React.useRef<HTMLInputElement>(null);

  const filteredJobs = React.useMemo(() => {
    if (!jobs) {
      return [];
    }

    if (filterStatus === 'ALL') {
      return jobs;
    }
    return jobs?.filter((it) => it.status === filterStatus);
  }, [jobs, filterStatus]);

  const isHighlight = React.useCallback(
    (id: number) => {
      if (productOrderChecks && jobResults) {
        const filteredJobResults = jobResults.filter((it) => {
          return it.jobId === id;
        });

        return (
          filteredJobResults.filter((it) => {
            return productOrderChecks.get(it.productOrderId) === true;
          }).length >= 1
        );
      }

      return false;
    },
    [productOrderChecks, jobResults]
  );

  const handleAllChecked = React.useCallback(() => {
    const entryJobsChecks = jobsChecks.entries();
    for (const item of entryJobsChecks) {
      const [id] = item;

      jobsChecks.set(id, !allChecked);
    }
    forceUpdate();
    setAllChecked(!allChecked);
  }, [jobsChecks, forceUpdate, allChecked]);

  const handleCheked = React.useCallback(
    (id: number) => {
      if (allChecked) {
        setAllChecked(false);
      }

      onChecked(id);

      if (!allChecked) {
        const entryJobsChecks = jobsChecks.entries();
        let flag = true;
        for (const item of entryJobsChecks) {
          const [, bool] = item;
          flag = bool;
          if (!flag) break;
        }
        setAllChecked(flag);
      }
    },
    [allChecked, jobsChecks, onChecked]
  );

  const fileDownloads = React.useCallback(
    (id: number) => {
      const files = jobFileMap.get(id) ?? null;

      if (files === null) return;

      const filteredFiles = files.filter((it) => {
        return it.fileType === 'CONTENTS_FILE';
      });

      for (const item of filteredFiles) {
        downloadAPIFile(item.file);
      }
    },
    [jobFileMap]
  );

  React.useEffect(() => {
    if (jobs) {
      for (const item of jobs) {
        jobsChecks.set(item.id, false);
      }
      forceUpdate();
    }
  }, [filterStatus, forceUpdate, jobs, jobsChecks]);

  const get = React.useCallback(async () => {
    const jobRes = await getJobsByProjectId(projectId);

    for (const item of jobRes.data) {
      jobsChecks.set(item.id, false);

      if (item.managerId) {
        const accountRes = await api.account.item(item.managerId).get();

        if (isAxiosError(accountRes)) {
          throw accountRes;
        }

        jobManagerMap.set(item.id, accountRes.data);
      }

      const filesRes = await api.jobs.item(item.id).files.get();

      if (isAxiosError(filesRes)) {
        throw filesRes;
      }

      if (filesRes.data) {
        jobFileMap.set(item.id, filesRes.data);
      }
    }

    setJobs(jobRes.data);
    setAllChecked(false);
  }, [jobFileMap, jobManagerMap, jobsChecks, projectId, setJobs]);

  const put = React.useCallback(async (data: Partial<JobsResponse>) => {
    if (data.id === undefined) return;
    const res = await api.jobs.item(data.id!).put(data);
    if (isAxiosError(res)) {
      throw res;
    }
  }, []);

  const updateStatus = React.useCallback(
    async (id: number, status: JobsStatus) => {
      if (window.confirm('업데이트 하시겠습니까?')) {
        try {
          const res = await api.jobs.item(id).status(status);
          if (isAxiosError(res)) {
            throw res;
          }

          get();
        } catch (err) {
          errorLogger.error(err);
        }
      }
    },
    [get]
  );

  const handleRequestedStatusCallback = React.useCallback(
    (jobId: number) => {
      updateStatus(jobId, 'REGISTERED');
      get();
    },
    [get, updateStatus]
  );

  const handleAdminSelectSubmit = React.useCallback(
    async (account: AccountResponseWithCompany[]) => {
      const [target] = account;
      if (!target) {
        return;
      }

      try {
        const job = jobs?.find((it) => it.id === adminSelectId);

        if (job) {
          const request = {
            ...job,
            managerId: target.account.id,
          };

          const res = await api.jobs.item(job.id).put(request);
          if (isAxiosError(res)) {
            throw res;
          }
        }

        setAdminSelectVisible(false);

        get();
      } catch (error) {
        errorLogger.error(error);
      }
    },
    [jobs, get, adminSelectId]
  );

  const onSubmitJobsStatusUpdate = React.useCallback(
    async (statusUpdatingJobs: JobsStatusUpdate[]) => {
      try {
        const res = await api.jobs.patch(statusUpdatingJobs);
        if (isAxiosError(res)) {
          throw res;
        }

        get();
      } catch (error) {
        errorLogger.error(error);
      }
    },
    [get]
  );

  const handleDismiss = React.useCallback(() => {
    setAdminSelectVisible(false);
    setAdminSelectId(undefined);
  }, [setAdminSelectVisible, setAdminSelectId]);

  const handleContentEdit = React.useCallback(
    (contentId: number) => {
      const path = generatePath(AppPaths.detailContent.path, { id: contentId });
      history.push(path);
    },
    [history]
  );

  const handleEditName = React.useCallback(
    async (job: JobsResponse) => {
      if (!editNameRef.current?.value || editNameRef.current?.value === job.name) {
        return setEditNameId(undefined);
      }

      await put({
        ...job,
        name: editNameRef.current?.value,
      }).catch(errorLogger.error);
      await get().catch(errorLogger.error);

      setEditNameId(undefined);
    },
    [get, put]
  );

  React.useEffect(() => {
    if (typeof editNameId === undefined) return;

    editNameRef.current?.focus();
  }, [editNameId]);

  const handleEditWorkSize = React.useCallback(
    async (job: JobsResponse) => {
      if (!editWorkSizeRef.current?.value || editWorkSizeRef.current?.value === job.workSize?.toString()) {
        return;
      }

      await put({
        ...job,
        workSize: Number(editWorkSizeRef.current?.value),
      }).catch(errorLogger.error);
      await get().catch(errorLogger.error);

      setEditNameId(undefined);
    },
    [get, put]
  );

  React.useEffect(() => {
    if (typeof editWorkSizeId === undefined) return;

    editWorkSizeRef.current?.focus();
  }, [editWorkSizeId]);

  const handlEditDeadLine = React.useCallback(
    async (job: JobsResponse) => {
      if (
        !editDeadLineRef.current?.value ||
        !job.scheduledDeadlineDateTime ||
        editDeadLineRef.current?.value === dateFormat(job.scheduledDeadlineDateTime, 'YYYY.MM.DD')
      ) {
        return;
      }

      await put({
        ...job,
        scheduledDeadlineDateTime: new Date(editDeadLineRef.current?.value.replaceAll('.', '-')).toISOString(),
      }).catch(errorLogger.error);
      await get().catch(errorLogger.error);

      setEditNameId(undefined);
    },
    [get, put]
  );

  React.useEffect(() => {
    if (typeof editDeadLineId === undefined) return;

    editDeadLineRef.current?.focus();
  }, [editDeadLineId]);

  useMount(() => {
    get().catch(errorLogger.error);
  });

  return (
    <JobListWrapper>
      <Row className={'title'}>
        <Title>작업 콘텐츠</Title>
        <Link to={`${AppPaths.createJob.path}?project=${projectId}`}>작업콘텐츠 추가하기</Link>
      </Row>
      <Row className={'buttonWrap'}>
        <Row className={'modalButtonWrap'}>
          <JobStatusUpdateModal
            jobIds={filteredJobs
              .filter((it) => jobsChecks.get(it.id))
              .map((it) => {
                return it.id;
              })}
            onSubmitHandler={onSubmitJobsStatusUpdate}
          />
          <ButtonBase
            onClick={() => {
              setFilesModalVisible(true);
            }}
            disabled={!filteredJobs.filter((it) => jobsChecks.get(it.id)).length}
          >
            첨부파일 확인
          </ButtonBase>
          <FileDownloadModal
            title={'작업 콘텐츠 첨부파일'}
            type={'job'}
            visible={filesModalVisible}
            onVisible={setFilesModalVisible}
            jobs={filteredJobs.filter((it) => jobsChecks.get(it.id))}
            jobFileMap={jobFileMap}
            transactionId={transactionId}
          />
        </Row>
        <Row className={'statusWrap'}>
          <ButtonBase className={cn(filterStatus === 'ALL' && 'active')} onClick={() => setFilterStatus('ALL')}>
            전체 {jobStatus.ALL || 0}
          </ButtonBase>
          <ButtonBase
            className={cn(filterStatus === 'REQUESTED' && 'active')}
            onClick={() => setFilterStatus('REQUESTED')}
          >
            승인대기 {jobStatus.REQUESTED || 0}
          </ButtonBase>
          <ButtonBase
            className={cn(filterStatus === 'REGISTERED' && 'active')}
            onClick={() => setFilterStatus('REGISTERED')}
          >
            작업대기 {jobStatus.REGISTERED || 0}
          </ButtonBase>
          <ButtonBase
            className={cn(filterStatus === 'PROCESSING' && 'active')}
            onClick={() => setFilterStatus('PROCESSING')}
          >
            작업 중 {jobStatus.PROCESSING || 0}
          </ButtonBase>
          <ButtonBase
            className={cn(filterStatus === 'REVIEWABLE' && 'active')}
            onClick={() => setFilterStatus('REVIEWABLE')}
          >
            검수대기 {jobStatus.REVIEWABLE || 0}
          </ButtonBase>
          <ButtonBase className={cn(filterStatus === 'DONE' && 'active')} onClick={() => setFilterStatus('DONE')}>
            작업완료 {jobStatus.DONE || 0}
          </ButtonBase>
        </Row>
      </Row>

      <Column>
        <Row>
          <span>
            <CheckBox onChange={handleAllChecked} checked={allChecked} />
          </span>
          <span>콘텐츠ID</span>
          <span>콘텐츠명</span>
          <span>작업량</span>
          <span>진행상태</span>
          <span>상태 업데이트</span>
          <span>최초생성일</span>
          <span>납품예정일</span>
          <span>담당PM</span>
          <span>다운로드</span>
          <span>상세보기</span>
        </Row>
        {filteredJobs.map((it) => (
          <Row key={it.id} className={cn(isHighlight(it.id) && 'isHighLight')}>
            <span>
              <CheckBox onChange={() => handleCheked(it.id)} checked={jobsChecks.get(it.id)} />
            </span>
            <span>CON-{it.id}</span>

            {editNameId === it.id && (
              <span>
                <input
                  type={'text'}
                  defaultValue={it.name}
                  ref={editNameRef}
                  onBlur={() => handleEditName(it)}
                  onKeyDown={(e) => handleEnterKeyDown(e, editNameRef)}
                />
              </span>
            )}
            {editNameId !== it.id && <span onDoubleClick={() => setEditNameId(it.id)}>{it.name}</span>}

            {editWorkSizeId === it.id && (
              <span>
                <input
                  type={'number'}
                  defaultValue={it.workSize}
                  ref={editWorkSizeRef}
                  onBlur={() => handleEditWorkSize(it)}
                  onKeyDown={(e) => handleEnterKeyDown(e, editWorkSizeRef)}
                />
              </span>
            )}
            {editWorkSizeId !== it.id && (
              <span onDoubleClick={() => setEditWorkSizeId(it.id)} className={cn(!it.workSize && 'notWorkSize')}>
                {it.workSize ?? '###'}
              </span>
            )}

            <span>
              <StatusLabel status={it.status} />
            </span>
            <span className={'buttonWrap'}>
              {it.status === 'PROCESSING' && (
                <>
                  <ButtonBase color={'blue'} onClick={() => updateStatus(it.id, 'DONE')}>
                    작업완료
                  </ButtonBase>
                  <ButtonBase color={'red'} onClick={() => updateStatus(it.id, 'DROP')}>
                    작업취소
                  </ButtonBase>
                </>
              )}
              {it.status === 'REVIEWABLE' && (
                <>
                  <ButtonBase color={'blue'} onClick={() => updateStatus(it.id, 'DONE')}>
                    작업완료
                  </ButtonBase>
                  <ButtonBase color={'red'} onClick={() => updateStatus(it.id, 'DROP')}>
                    작업취소
                  </ButtonBase>
                </>
              )}

              {it.status === 'REQUESTED' && (
                <>
                  <ButtonBase color={'blue'}>
                    <ApprovedJobResultModal
                      targetJob={it}
                      productOrderMap={productOrderMap}
                      buttonName={'승인'}
                      callback={() => handleRequestedStatusCallback(it.id)}
                    />
                  </ButtonBase>
                  <ButtonBase color={'red'} onClick={() => updateStatus(it.id, 'DROP')}>
                    작업취소
                  </ButtonBase>
                </>
              )}
              {it.status === 'REGISTERED' && (
                <>
                  <ButtonBase color={'blue'} onClick={() => updateStatus(it.id, 'PROCESSING')}>
                    작업시작
                  </ButtonBase>
                  <ButtonBase color={'red'} onClick={() => updateStatus(it.id, 'DROP')}>
                    작업취소
                  </ButtonBase>
                </>
              )}
              {it.status === 'DROP' && (
                <>
                  <ButtonBase color={'blue'} onClick={() => updateStatus(it.id, 'PROCESSING')}>
                    작업시작
                  </ButtonBase>
                  <ButtonBase disabled>작업취소</ButtonBase>
                </>
              )}
              {it.status === 'DONE' && (
                <>
                  <ButtonBase color={'blue'} onClick={() => updateStatus(it.id, 'PROCESSING')}>
                    작업시작
                  </ButtonBase>
                  <ButtonBase disabled>작업취소</ButtonBase>
                </>
              )}
            </span>
            <span>{dateFormat(it.createDateTime, 'YYYY.MM.DD')}</span>
            {editDeadLineId === it.id && (
              <span>
                <input
                  type={'text'}
                  ref={editDeadLineRef}
                  defaultValue={it.scheduledDeadlineDateTime && dateFormat(it.scheduledDeadlineDateTime, 'YYYY.MM.DD')}
                  placeholder={'yyyy.mm.dd'}
                  onBlur={() => handlEditDeadLine(it)}
                  onKeyDown={(e) => handleEnterKeyDown(e, editDeadLineRef)}
                />
              </span>
            )}

            {editDeadLineId !== it.id && (
              <span
                className={it.scheduledDeadlineDateTime && cn(recentDate(it.scheduledDeadlineDateTime, 3) && 'warning')}
                onDoubleClick={() => setEditDeadLineId(it.id)}
              >
                {it.scheduledDeadlineDateTime && dateFormat(it.scheduledDeadlineDateTime, 'YYYY.MM.DD')}
              </span>
            )}
            <span>
              {(jobManagerMap.get(it.id)?.role === 'ADMIN' && jobManagerMap.get(it.id)?.name) || (
                <ButtonBase
                  onClick={() => {
                    setAdminSelectVisible(true);
                    setAdminSelectId(it.id);
                  }}
                >
                  <img src={ICN_PLUS} alt={''} />
                </ButtonBase>
              )}
            </span>
            <span>
              <ButtonBase disabled={!jobFileMap.get(it.id)} onClick={() => fileDownloads(it.id)}>
                <img src={ICN_DOWNLOAD} alt={'download'} />
              </ButtonBase>
            </span>
            <span>
              <ButtonBase onClick={() => handleContentEdit(it.id)}>
                <img src={ICN_PLUS} alt={'detail'} />
              </ButtonBase>
            </span>
          </Row>
        ))}
      </Column>
      <AccountSelectModal
        title={'담당 PM 선택'}
        visible={adminSelectVisible}
        options={{ requestData: { roles: 'ADMIN' } }}
        onDismiss={handleDismiss}
        onSubmit={handleAdminSelectSubmit}
      />
    </JobListWrapper>
  );
}

export default JobList;
