import { Eventer, EventerRemoveHandler, once } from '@voithru/front-core';
import { NamedFile } from './files';
import MultipartUpload from './MultipartUpload';

type Listener = (uploader: MultipartUpload) => void;

class UploadService {
  public get uploaders(): readonly MultipartUpload[] {
    return this.multipart;
  }

  private eventerProgress = new Eventer<Listener>();
  private eventerStart = new Eventer<Listener>();
  private eventerCancel = new Eventer<Listener>();
  private eventerDone = new Eventer<Listener>();

  constructor(private multipart: MultipartUpload[] = []) {
    this.eventerDone.addEventListener(() => this.action());

    this.addEventListener = this.addEventListener.bind(this);
    this.register = this.register.bind(this);
  }

  public addEventListener(type: 'progress', listen: Listener): EventerRemoveHandler;
  public addEventListener(type: 'start', listen: Listener): EventerRemoveHandler;
  public addEventListener(type: 'cancel', listen: Listener): EventerRemoveHandler;
  public addEventListener(type: 'done', listen: Listener): EventerRemoveHandler;
  public addEventListener(type: 'progress' | 'start' | 'cancel' | 'done', listen: Listener) {
    switch (type) {
      case 'progress':
        return this.eventerProgress.addEventListener(listen);
      case 'start':
        return this.eventerStart.addEventListener(listen);
      case 'cancel':
        return this.eventerCancel.addEventListener(listen);
      case 'done':
        return this.eventerDone.addEventListener(listen);
    }
  }

  public register(arg: MultipartUpload): MultipartUpload;
  public register(arg: NamedFile): MultipartUpload;
  public register(arg: MultipartUpload | NamedFile) {
    const uploader = arg instanceof MultipartUpload ? arg : new MultipartUpload(arg);
    uploader.addEventListener('progress', () => this.eventerDone.run(uploader));
    uploader.addEventListener('start', () => this.eventerDone.run(uploader));
    uploader.addEventListener('cancel', () => this.eventerDone.run(uploader));
    uploader.addEventListener('done', () => this.eventerDone.run(uploader));
    this.multipart.push(uploader);
    this.action();

    return uploader;
  }

  public unregister = (fileId: string) => {
    const targetIndex = this.multipart.findIndex((it) => it.namedFile.id === fileId);
    if (targetIndex === undefined) {
      throw new Error('UploadManager.unregister: Not Found');
    }

    const target = this.multipart[targetIndex];
    target.cancel();
    this.multipart.splice(targetIndex, 1);
  };

  private action = once(async () => {
    const target = this.uploaders.find((it) => ['INIT', 'READY', 'CANCEL'].includes(it.status));
    if (!target) {
      return;
    }

    try {
      if (target.status === 'INIT') {
        await target.ready('PROJECT');
      }

      target.start();
    } catch (error) {
      console.error('UploadManager.action:', error);
      this.stop();
    }
  });

  public stop = () => {
    const target = this.uploaders.find((it) => ['START'].includes(it.status));
    if (!target) {
      return;
    }

    return target.cancel();
  };
}

export default UploadService;
