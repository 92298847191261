import { sleep } from '@voithru/front-core';
import { nanoid } from 'nanoid';
import api from 'src/api';
import { isAxiosError } from 'src/utils/api/axios';

export interface SelectFileOptions {
  accept?: string | string[];
  multiple?: boolean;
  directory?: boolean;
  type?: string;
}

export function selectFile(options: SelectFileOptions = {}) {
  const { accept = '', multiple = false, directory = false } = options;

  const input = document.createElement('input');
  input.type = 'file';
  input.accept = Array.isArray(accept) ? accept.join(',') : accept;
  input.multiple = multiple;
  if (directory) {
    input.setAttribute('webkitdirectory', '');
  }

  return new Promise<File[] | null>((res) => {
    function onChange(e: Event) {
      const input = e.currentTarget as HTMLInputElement;
      if (!input.files) {
        return res(null);
      }

      const arr = Array.from(input.files).filter((it) => !/^\./.test(it.name));
      return res(arr);
    }

    function onBlur() {
      document.removeEventListener('mousedown', onBlur);
      document.removeEventListener('keydown', onBlur);

      res(null);
    }

    input.addEventListener('change', onChange);

    document.addEventListener('mousedown', onBlur, { once: true });
    document.addEventListener('keydown', onBlur, { once: true });
    input.click();
  }).finally(() => {
    input.remove();
  });
}

export interface NamedFile {
  id: string;
  name: string;
  file: File;
  webkitRelativePath?: string;
}

export function isDirectory(entry: FileSystemEntry): entry is FileSystemDirectoryEntry {
  return 'createReader' in entry;
}

export function isFile(entry: FileSystemEntry): entry is FileEntry {
  return 'file' in entry;
}

export async function getFileEntries(entry: FileSystemEntry | FileSystemDirectoryEntry): Promise<NamedFile[]> {
  if (isDirectory(entry)) {
    const reader = entry.createReader();
    const entries = await new Promise<FileSystemEntry[]>((res, rej) => reader.readEntries(res, rej));

    const val: NamedFile[] = [];
    for (const e of entries) {
      const arr = await getFileEntries(e);
      val.push(...arr);
    }

    return val;
  } else if (isFile(entry)) {
    const file = await new Promise<File>((res) => entry.file(res));

    const path = entry.fullPath.replace(/^\//, '');
    const named: NamedFile = { id: nanoid(), name: path, file };
    return [named];
  }

  return [];
}

export function download(src: string) {
  const frame = document.createElement('iframe');
  frame.src = src;
  frame.style.display = 'none';
  document.body.appendChild(frame);
  sleep(200000).then(() => frame.remove());
}

export function downloadAsFile(blob: Blob, filename: string) {
  const blobURL = window.URL.createObjectURL(blob);
  download(blobURL);
  window.URL.revokeObjectURL(blobURL);
}

export async function downloadAPIFile(data: { id: string; name: string }) {
  const res = await api.file.item(data.id).downloadUrl();
  if (isAxiosError(res)) {
    throw res;
  }

  download(res.data);
}

export const separateFileIntoNameAndExtension = (fileNameWithExtension?: string | null) => {
  if (!fileNameWithExtension) {
    return [fileNameWithExtension];
  }

  const seprateArray = fileNameWithExtension.split('.');
  const fileExtension = '.' + seprateArray[seprateArray.length - 1];

  const fileName = fileNameWithExtension.replace(fileExtension, '');

  return [fileName, fileExtension];
};

export const downloadUrlAsFile = (blob: Blob, filename: string) => {
  const fileUrl = URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = fileUrl;
  tempLink.setAttribute('download', filename);

  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }

  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  window.URL.revokeObjectURL(fileUrl);
};
