import { once, UnknownFunction } from '@voithru/front-core';
import ManualPromise, { PromiseReject, PromiseResolve } from './ManualPromise';
import Queue, { ReadonlyQueue } from './queue';

export interface QueueTaskAction<F extends UnknownFunction = UnknownFunction> {
  fn: F;
  args: Parameters<F>;
}

type QueueTaskActionWithPromise<F extends UnknownFunction = UnknownFunction> = QueueTaskAction<F> & {
  resolve: PromiseResolve<ReturnType<F>>;
  reject: PromiseReject;
};

class QueueTask {
  #queue = new Queue<QueueTaskActionWithPromise>();
  public get queue(): ReadonlyQueue<QueueTaskActionWithPromise> {
    return this.#queue;
  }

  public dispatch = async <F extends UnknownFunction>(fn: F, ...args: Parameters<F>): Promise<ReturnType<F>> => {
    const { promise, resolve, reject } = new ManualPromise<ReturnType<F>>();
    const action: QueueTaskActionWithPromise<F> = { fn, args, resolve, reject };
    this.#queue.enqueue(action as QueueTaskActionWithPromise);
    this.#loop();

    return await promise;
  };

  #loop = once(async () => this.#queue.dequeueEach(this.#runner, true));

  #runner = async (action: QueueTaskActionWithPromise) => {
    const { fn, args, resolve, reject } = action;
    try {
      const result = fn(...args);

      if (result instanceof Promise) {
        return await result.then(resolve).catch(reject);
      }

      return resolve(result);
    } catch (error) {
      reject(error);
    }
  };
}

export default QueueTask;
