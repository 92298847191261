import { useQuery } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import StatusLabel from 'src/components/atoms/StatusLabel';
import { ButtonBase } from 'src/components/atoms/styled/button';
import { Title } from 'src/components/atoms/styled/element';
import { Column, Row } from 'src/components/atoms/styled/layout';
import { JobsStatus } from 'src/types/api/Job';
import { AccountResponseWithCompany } from 'src/types/api/User';
import ContentInfo from './components/ContentInfo';
import ContentResultFiles from './components/ContentResultFiles';
import { DetailContentWrapper } from './styled';
import ICN_SEARCH from 'src/assets/icons/icn-search.svg';
import { nanoid } from 'nanoid';
import { handleEnterKeyDown } from 'src/utils/dom';
import AccountSelectModal from 'src/components/organisms/AccountSelectModal';
import StatusChangeModal from 'src/components/organisms/StatusChangeModal';
import { isAxiosError } from 'src/utils/api/axios';
import { dateFormat } from 'src/utils/date';
import { ContentType } from 'src/utils/translate';
import { GQL_DETAIL_CONTENT } from './gql';
import { DetailContentQuery, DetailContentQueryVariables } from './gql.generated';

interface Match {
  id: string;
}

const TRANSACTION_ID = nanoid();
function ContentDetailPage() {
  const { id } = useParams<Match>();
  const jobId = React.useMemo(() => Number(id), [id]);

  const { data, loading, error, refetch } = useQuery<DetailContentQuery, DetailContentQueryVariables>(
    GQL_DETAIL_CONTENT,
    {
      variables: {
        id,
      },
      onError: errorLogger.error,
    }
  );

  const [editName, setEditName] = React.useState(false);
  const editNameRef = React.useRef<HTMLInputElement>(null);

  const [editSchedule, setEditSchedule] = React.useState(false);
  const editScheduleRef = React.useRef<HTMLInputElement>(null);

  const [openAccountModal, setOpenAccountModal] = React.useState(false);

  React.useEffect(() => {
    if (!editName) return;

    editNameRef.current?.focus();
  }, [editName]);

  /** UI */
  const [isStatusOpen, setStatusOpen] = React.useState(false);
  const handleStatusSubmit = React.useCallback(
    async (status: JobsStatus) => {
      const res = await api.jobs.item(jobId).status(status);
      if (isAxiosError(res)) {
        throw res;
      }

      setStatusOpen(false);
    },
    [jobId]
  );

  const handleAdminSelectSubmit = React.useCallback(
    async (account: AccountResponseWithCompany[]) => {
      const [target] = account;
      if (!target) {
        return;
      }

      const res = await api.jobs.patch([
        {
          id: jobId,
          managerId: target.account.id,
        },
      ]);

      if (isAxiosError(res)) {
        throw res;
      }

      await refetch({
        id: jobId.toString(),
      });

      setOpenAccountModal(false);
    },
    [jobId, refetch]
  );

  const handleEditName = React.useCallback(
    async (prevName: string) => {
      if (!editNameRef.current?.value || editNameRef.current?.value === prevName || !prevName) {
        return;
      }

      await api.jobs
        .item(jobId)
        .put({
          id: jobId,
          name: editNameRef.current?.value,
        })
        .catch(errorLogger.error);

      refetch({
        id: jobId.toString(),
      });

      setEditName(false);
    },
    [jobId, refetch]
  );

  const handleEditSchedule = React.useCallback(
    async (prevSchedule: string) => {
      if (!editScheduleRef.current?.value) {
        return;
      }
      const editDate = new Date(editScheduleRef.current?.value.replaceAll('.', '-'));
      if (
        (prevSchedule && dateFormat(prevSchedule, 'YYYY.MM.DD') !== dateFormat(editDate, 'YYYY.MM.DD')) ||
        !prevSchedule
      ) {
        return;
      }
      await api.jobs
        .patch([
          {
            id: jobId,
            scheduledDeadlineDateTime: editDate.toISOString(),
          },
        ])
        .catch(errorLogger.error);

      refetch({
        id: jobId.toString(),
      });

      setEditSchedule(false);
    },
    [jobId, refetch]
  );

  if (loading) return <div>loading...</div>;
  if (error) {
    return (
      <div>
        <h2>{error.name}</h2>
        <h3>{error.message}</h3>
      </div>
    );
  }

  const job = data?.job;

  if (!job) {
    return null;
  }

  return (
    <DetailContentWrapper>
      <Row>
        <Row>
          <ButtonBase onClick={() => setStatusOpen(true)}>
            <StatusLabel status={job.status} />
          </ButtonBase>
          <Title>
            <span>[C-{jobId}]</span>
            <span>[{job.project?.category && ContentType[job.project?.category]}]</span>
            {editName && (
              <input
                defaultValue={job.name ?? ''}
                ref={editNameRef}
                onBlur={() => handleEditName(job.name || '')}
                onKeyDown={(e) => handleEnterKeyDown(e, editNameRef)}
              />
            )}
            {!editName && <span onDoubleClick={() => setEditName(true)}>{job.name}</span>}
          </Title>
        </Row>
      </Row>
      <Row className={'admin'}>
        <Column className={'client'}>
          <Row>
            <span>프로젝트</span>
            <span>
              [P-{job.project?.id}]{job.project?.name}
            </span>
          </Row>
          <Row>
            <span>고객회사</span>
            <span>{job.account?.company?.name || '-'}</span>
          </Row>
          <Row>
            <span>담당자</span>
            <span>{job.account?.company?.manager?.name || '-'}</span>
          </Row>
        </Column>
        <Column className={'info'}>
          <Row>
            <span>담당 PM</span>
            <span>{job.manager?.name}</span>
            <ButtonBase onClick={() => setOpenAccountModal(true)}>
              <img src={ICN_SEARCH} alt={'PM Search'} />
            </ButtonBase>
            {openAccountModal && (
              <AccountSelectModal
                visible={true}
                title={'담당 PM 선택'}
                options={{ requestData: { roles: 'ADMIN' } }}
                onDismiss={() => setOpenAccountModal(false)}
                onSubmit={handleAdminSelectSubmit}
              />
            )}
          </Row>
          <Row>
            <span>작업콘텐츠 생성일</span>
            <span>{dateFormat(job.createDateTime, 'YYYY.MM.DD')}</span>
          </Row>
          <Row>
            <span>작업콘텐츠 최종 수정일</span>
            <span>{job.updateDateTime ? dateFormat(job.updateDateTime, 'YYYY.MM.DD') : ' - '}</span>
          </Row>
          <Row>
            <span>납품 예정일</span>

            {editSchedule && (
              <input
                ref={editScheduleRef}
                placeholder={'YYYY.MM.DD'}
                defaultValue={
                  (job.scheduledDeadlineDateTime && dateFormat(job.scheduledDeadlineDateTime, 'YYYY.MM.DD')) ?? ''
                }
                onBlur={() => handleEditSchedule(job.scheduledDeadlineDateTime || '')}
                onKeyDown={(e) => handleEnterKeyDown(e, editScheduleRef)}
              />
            )}
            {!editSchedule && (
              <span onDoubleClick={() => setEditSchedule(true)}>
                {(job.scheduledDeadlineDateTime && dateFormat(job.scheduledDeadlineDateTime, 'YYYY.MM.DD')) || '-'}
              </span>
            )}
          </Row>
          <Row>
            <span>납품 완료일</span>
            <span>{job.doneDateTime && dateFormat(job.doneDateTime, 'YYYY.MM.DD')}</span>
          </Row>
        </Column>
      </Row>
      <ContentInfo jobId={jobId} transactionId={TRANSACTION_ID} />
      <ContentResultFiles jobId={jobId} transactionId={TRANSACTION_ID} />

      <StatusChangeModal
        status={job.status}
        visible={isStatusOpen}
        onDismiss={() => setStatusOpen(false)}
        onSubmit={handleStatusSubmit}
      />
    </DetailContentWrapper>
  );
}

export default ContentDetailPage;
