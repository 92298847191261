export enum TaskName {
  project = '프로젝트',
  content = '작업콘텐츠',
  result = '작업결과물',
}

export enum TaskCode {
  PROJECT = 'P',
  JOB = 'CON',
  JOB_RESULT = 'JR',
}

export enum Status {
  REQUESTING = '작성중',
  REQUESTED = '승인대기',
  REGISTERED = '작업대기',
  PROCESSING = '작업중',
  REVIEWABLE = '검수대기',
  REVIEW_APPROVED = '검수승인',
  REVIEW_REJECTED = '검수거절',
  DONE = '작업완료',
  DROP = '작업취소',
}

export enum DocumentStatus {
  null = '미발행',
  REGISTERED = '발행완료',
  PROCESSED = '정산완료',
  CANCELLED = '발행취소',
}

export enum ContentType {
  WEBTOON = '웹툰',
  WEBNOVEL = '웹소설',
  VIDEO = '영상',
  DOCUMENT = '문서',
}

export enum WorkRange {
  null = '작업범위',
  TRANSLATION = '번역',
  CAPTION = '원어',
  SPOTTING_ONLY = '씽크',
  TIME_CODE = 'TC',
  BURN_IN = '번인만 진행',
  TYPESETTER_ONLY = '식자만 진행',
}

export enum ResultType {
  null = '납품형태',
  BURN_IN_BASIC = '번인 기본',
  BURN_IN_ADVANCED = '번인 고급',
  NO_BURN_IN = '번인 없음',
  TYPESETTING_PAGE_TO_PAGE = '식자 페이지>페이지',
  TYPESETTING_PAGE_TO_SCROLL = '식자 페이지>스크롤',
  TYPESETTING_SCROLL_TO_PAGE = '식자 스크롤>페이지',
  TYPESETTING_SCROLL_TO_SCROLL = '식자 스크롤>스크롤',
  NO_TYPESETTER = '식자 없음',
}

export enum Source_Language {
  null = '원본언어',
  KOREAN = '한국어',
  ENGLISH = '영어',
  JAPANESE = '일본어',
  SIMPLIFIED_CHINESE = '중국어-간체',
  TAIWAN_CHINESE = '중국어-번체',
  SPANISH = '스페인어',
  VIETNAMESE = '베트남어',
  INDONESIAN = '인니어',
  THAI = '태국어',
  RUSSIAN = '러시아어',
  FRENCH = '불어',
  PORTUGUESE = '포르투칼어',
  GERMAN = '독일어',
  ETC = '기타',
}

export enum Translate_Language {
  null = '번역언어',
  KOREAN = '한국어',
  ENGLISH = '영어',
  JAPANESE = '일본어',
  SIMPLIFIED_CHINESE = '중국어-간체',
  TAIWAN_CHINESE = '중국어-번체',
  SPANISH = '스페인어',
  VIETNAMESE = '베트남어',
  INDONESIAN = '인니어',
  THAI = '태국어',
  RUSSIAN = '러시아어',
  FRENCH = '불어',
  PORTUGUESE = '포르투칼어',
  GERMAN = '독일어',
  ETC = '기타',
}

export enum LanguageCounytryCode {
  KOREAN = 'ko',
  ENGLISH = 'en',
  JAPANESE = 'ja',
  SIMPLIFIED_CHINESE = 'zh-CN',
  TAIWAN_CHINESE = 'zhTW',
  SPANISH = 'es',
  VIETNAMESE = 'vi-VN',
  INDONESIAN = 'id',
  THAI = 'th',
  RUSSIAN = 'ru',
  FRENCH = 'fr',
  PORTUGUESE = 'pt',
  GERMAN = 'gm',
}

export enum WorkTarget {
  null = '작업대상',
  BASIC = '기본',
  VOICE_ONLY = '음성자막',
  SCREEN_ONLY = '화면자막',
  VOICE_AND_SCREEN = '음성+화면자막',
}

export enum AdditionalServices {
  NULL = '선택하지 않음',
  DISCOUNT_ON_DEFAULT_CAPTION = '기본자막할인',
  DISCOUNT_ON_DETACHED_LAYER = '레이어분리 할인',
  DISCOUNT_ON_BYPRODUCT = '중간작업물 할인',
  REQUEST_EDITING = '수정작업추가',
  URGENT_TASK = '패스트트랙',
}

export enum BusinessType {
  PERSONAL = '개인',
  CORPORATE = '법인',
}

export enum DifficultyType {
  HIGH = '고',
  MIDDLE = '중',
  BASIC = '기본',
}

export enum ExpectedQualityType {
  HIGH = '고',
  MIDDLE = '중',
  BASIC = '기본',
}

export enum VolumeType {
  LARGE = '대',
  MIDDLE = '중',
  SMALL = '소',
}

export enum WorkSizeUnit {
  EPISODE = '화',
  MINUTE = '분',
  WORD = '개',
}

export function getRelativeDateTranslate(
  type: 'BEFORE' | 'AFTER',
  unit: 'SEC' | 'MIN' | 'HOUR' | 'DAY' | 'WEEK' | 'MONTH' | 'YEAR' | 'MOMENT'
) {
  switch (unit) {
    case 'MOMENT':
      if (type === 'BEFORE') {
        return '얼마 전';
      } else {
        return '얼마 후';
      }
    case 'SEC':
      if (type === 'BEFORE') {
        return '초 전';
      } else {
        return '초 후';
      }
    case 'MIN':
      if (type === 'BEFORE') {
        return '분 전';
      } else {
        return '분 후';
      }
    case 'HOUR':
      if (type === 'BEFORE') {
        return '시간 전';
      } else {
        return '시간 후';
      }
    case 'DAY':
      if (type === 'BEFORE') {
        return '일 전';
      } else {
        return '일 후';
      }
    case 'WEEK':
      if (type === 'BEFORE') {
        return '주 전';
      } else {
        return '주 후';
      }
    case 'MONTH':
      if (type === 'BEFORE') {
        return '달 전';
      } else {
        return '달 후';
      }
    case 'YEAR':
      if (type === 'BEFORE') {
        return '년 전';
      } else {
        return '년 후';
      }
  }
}
