import EventerGroup from './events';

export type PromiseResolve<T> = (value: T | PromiseLike<T>) => void;
export type PromiseReject<E = unknown> = (error?: E) => void;

interface PromiseEvents<T, E = unknown> {
  resolve(value: T | PromiseLike<T>): void;
  reject(error?: E): void;
}

class ManualPromise<T> {
  #events = new EventerGroup<PromiseEvents<T>>();

  public readonly promise = new Promise<T>((res, rej) => {
    this.#events.addEventListener('resolve', res);
    this.#events.addEventListener('reject', rej);
  });

  public resolve = (...args: Parameters<PromiseEvents<T>['resolve']>) => this.#events.run('resolve', ...args);
  public reject = (...args: Parameters<PromiseEvents<T>['reject']>) => this.#events.run('reject', ...args);
}

export default ManualPromise;
