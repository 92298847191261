import axios from 'axios';
import apiProject from 'src/api/project';
import { API_ORIGIN_SERVER, API_SERVER, API_SERVER_TIMEOUT } from 'src/constants/API';
import apiAccount from './account';
import apiAuth from './auth';
import AuthorizationManager from './AuthorizationManager';
import apiCompanies from './companies';
import apiFile from './file';
import apiJobResult from './jobResult';
import apiJobs from './jobs';
import apiProducts from './product';
import apiCompany from './company';
import apiSignUp from './signUp';
import apiProductOrder from './productOrder';
import { apiUtils } from './utils';
import apiDocuments from './documents';
import apiWorkerJobResultGrants from './workerJobResultGrants';
import apiVideoProcessRequest from './videoProcessRequest';
import apiEntityHistory from './entityHIstory';
import apiCredential from './credential';

const axiosAPI = axios.create({
  baseURL: API_SERVER,
  timeout: API_SERVER_TIMEOUT,
  withCredentials: true,
});

const axiosAuthAPI = axios.create({
  baseURL: API_ORIGIN_SERVER,
  timeout: API_SERVER_TIMEOUT,
  withCredentials: true,
});
const api = {
  instance: axiosAPI,
  manager: {
    auth: new AuthorizationManager('partners', axiosAPI),
  },

  auth: apiAuth(axiosAuthAPI),
  account: apiAccount(axiosAPI),
  companies: apiCompanies(axiosAPI),
  project: apiProject(axiosAPI),
  jobs: apiJobs(axiosAPI),
  jobResult: apiJobResult(axiosAPI),
  signUp: apiSignUp(axiosAPI),
  file: apiFile(axiosAPI),
  company: apiCompany(axiosAPI),
  utils: apiUtils(),
  productOrder: apiProductOrder(axiosAPI),
  product: apiProducts(axiosAPI),
  document: apiDocuments(axiosAPI),
  entityHistory: apiEntityHistory(axiosAPI),
  credential: apiCredential(axiosAPI),

  workerJobResultGrants: apiWorkerJobResultGrants(axiosAPI),
  videoProcessRequest: apiVideoProcessRequest(axiosAPI),
};

export default api;
